/* Copyright Levelise Ltd 2023 - 2025 */
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PERMISSIONS, customerInfoTabs } from '../../utils/constants';
import { Button } from '../Form';
import FleetService from '../../services/fleet-service';
import { hasPermission, validateEmail, validatePhoneNumber } from '../../utils/utils';
import CustomerInfoTab from '../CustomerInfoTab';
import DeleteBtn from '../DeleteBtn';
import Popup from '../Popup';
import FleetContext from '../../contexts/FleetContext';
import { useAlert } from 'react-alert';

const CustomerInformationSection = ({
	customer,
	customerFacilities,
	handleFetchSpecification,
	updateCustomer,
	customerUsers,
	updateCustomerUsers,
}) => {
	const navigate = useNavigate();

	const alert = useAlert();

	const fleetContext = useContext(FleetContext);

	const { information } = customerInfoTabs;

	const [editing, setEditing] = useState(false);
	const [updateUser, setUpdateUser] = useState(true);
	const [customerUserSameEmail, setCustomerUserSameEmail] = useState(null);
	const [userExistsForCustomer, setUserExistsForCustomer] = useState(false);
	const [loading, setLoading] = useState(false);
	const [email, setEmail] = useState('');

	const [forename, setForename] = useState('');
	const [surname, setSurname] = useState('');
	const [phone, setPhone] = useState('');
	const [mobile, setMobile] = useState('');

	const [noInfoChange, setNoInfoChange] = useState(true);

	const [confirmDeletion, setConfirmDeletion] = useState(false);
	const [loadingDisable, setLoadingDisable] = useState(false);

	const onEditClick = () => {
		if (customer) {
			setEmail(customer?.email || '');
			setForename(customer?.name || '');
			setSurname(customer?.surname || '');
			setPhone(customer?.phone || '');
			setMobile(customer?.mobile || '');
		}

		setEditing(true);
	};

	const onCheckboxClick = () => {
		setUpdateUser(!updateUser);
	};

	const handleEmailChange = (e) => {
		setEmail(e.target.value);
	};

	const handleForenameChange = (e) => {
		setForename(e.target.value);
	};

	const handleSurnameChange = (e) => {
		setSurname(e.target.value);
	};

	const handlePhoneChange = (e) => {
		setPhone(e.target.value);
	};
	const handleMobileChange = (e) => {
		setMobile(e.target.value);
	};

	const handleCloseModal = () => {
		setConfirmDeletion(false);
	};

	const filterCustomerList = (customerId) => {
		const filteredCustomerList = fleetContext.customers.filter((cust) => cust.customerId !== customerId);

		fleetContext.setCustomers(filteredCustomerList);
	};

	const handleDisableCustomer = async () => {
		if (!customer || !customer?.customerId || !hasPermission(PERMISSIONS.CAN_ACCESS_CUSTOMER_CONSENT)) {
			return;
		}

		setLoadingDisable(true);
		try {
			const res = await FleetService.disableCustomer(customer.customerId);

			if (res) {
				filterCustomerList(customer.customerId);

				setConfirmDeletion(false);

				navigate(-1);
			}
			setLoadingDisable(false);
		} catch (err) {
			setLoadingDisable(false);
			setConfirmDeletion(false);
			alert.show('Something went wrong, please try again.');
		}
	};

	const getChangedInfoValues = () => {
		let obj = {};

		if (customer) {
			if (email && customer?.email !== email && validateEmail(email)) {
				obj.email = email.trim();
			}

			if (forename && forename.trim() && customer?.name !== forename.trim()) {
				obj.forename = forename.trim();
			}

			if (surname && surname.trim() && customer?.surname !== surname.trim()) {
				obj.surname = surname.trim();
			}

			if (phone && phone.trim() && customer?.phone !== phone.trim()) {
				obj.phone = phone.trim();
			}

			if (mobile && mobile.trim() && customer?.mobile !== mobile.trim()) {
				obj.mobile = mobile.trim();
			}
		}

		return obj;
	};

	const handleUpdateCustomer = async () => {
		if (!customer || !customer?.customerId) {
			return;
		}
		let userFormerEmail;

		const changeObj = getChangedInfoValues(); // { email, forename, surname, phone, mobile };

		if (!Object.keys(changeObj).length > 0) {
			return;
		}

		setLoading(true);
		try {
			if (Object.hasOwn(changeObj, 'email') && userExistsForCustomer && updateUser) {
				if (!customerUserSameEmail || !customerUserSameEmail?.username) {
					alert.show('Can not update user email, user not found!');
					setLoading(false);
					return;
				}

				const updateUserRes = await FleetService.modifyUserEmail(customerUserSameEmail?.username, {
					email: changeObj.email,
				});
				if (updateUserRes) {
					userFormerEmail = customerUserSameEmail.email;
				}
			}

			const res = await FleetService.updateCustomer(customer.customerId, changeObj);
			if (res) {
				updateCustomer({
					...customer,
					...changeObj,
					name: changeObj.forename || customer?.name || '',
				});
			}

			if (userExistsForCustomer && updateUser && userFormerEmail) {
				const newUsersList = customerUsers?.filter((ele) => ele.username !== customerUserSameEmail?.username);

				updateCustomerUsers([...newUsersList, { ...customerUserSameEmail, email: changeObj.email }]);
			}
			setLoading(false);
			setEditing(false);
		} catch (err) {
			let message = 'Something went wrong! Please try again.';
			if (userFormerEmail) {
				try {
					const updateUserRes = await FleetService.modifyUserEmail(customerUserSameEmail?.username, {
						email: userFormerEmail,
					});
				} catch (error) {
					message =
						'Something went wrong! Please make sure the user email is set correctly. Please try again.';
				}
			}
			setLoading(false);
			alert.show(message);
		}
	};

	useEffect(() => {
		if (!customerUsers || !customer) {
			return;
		}

		const user = customerUsers?.filter((ele) => ele.email === customer?.email && ele.role === 'ROLE_USER');

		if (user && user?.length > 0) {
			setCustomerUserSameEmail(user[0]);
			setUserExistsForCustomer(true);
		} else {
			setCustomerUserSameEmail(null);
			setUserExistsForCustomer(false);
		}
	}, [customerUsers, customer]);

	useEffect(() => {
		if (
			customer &&
			((customer?.email !== email.trim() && validateEmail(email)) ||
				customer?.name !== forename.trim() ||
				customer?.surname !== surname.trim() ||
				customer?.phone !== phone.trim() ||
				(!customer?.mobile && mobile.trim())||
				(customer?.mobile && customer?.mobile !== mobile.trim())
			)
		) {
			setNoInfoChange(false);
		} else {
			setNoInfoChange(true);
		}

		if (customer?.email !== email.trim() && !validateEmail(email)) {
			setNoInfoChange(true);
		}
		if (customer?.phone !== phone.trim() && !validatePhoneNumber(phone)) {
			setNoInfoChange(true);
		}
		if (((customer?.mobile && customer?.mobile !== mobile.trim()) || (!customer?.mobile && mobile.trim()))
			&& !validatePhoneNumber(mobile)) {
			setNoInfoChange(true);
		}
	}, [customer, email, forename, surname, phone, mobile]);

	return (
		<div className="left-side-container">
			<div className="customer-screen-headers" style={{ paddingBottom: 1 }}>
				<h2>{information}</h2>
				<span className="right-side-component">
					<div className="customer-edit-button-container">
						{editing ? (
							<>
								{hasPermission(PERMISSIONS.CAN_EDIT_CUSTOMER_CONSENT) && customer ? (
									<DeleteBtn
										onDeleteClick={() => setConfirmDeletion(true)}
										style={{ marginRight: 15 }}
									/>
								) : null}
								<Button
									className="buttons-customer"
									onClick={() => {
										setUpdateUser(true);
										setEditing(false);
									}}
									data-cy="info-edit-cancel-button"
								>
									Cancel
								</Button>
								<Button
									className="buttons-customer done-button-customer"
									disabled={loading || noInfoChange}
									onClick={() => handleUpdateCustomer()}
									data-cy="info-edit-done-button"
								>
									{loading ? 'Loading...' : 'Done'}
								</Button>
							</>
						) : (
							<Button
								className="buttons-customer"
								onClick={() => onEditClick()}
								data-cy="info-edit-button"
							>
								Edit
							</Button>
						)}
					</div>
				</span>
			</div>
			<>
				<CustomerInfoTab
					customer={customer}
					email={email}
					forename={forename}
					surname={surname}
					phone={phone}
					mobile={mobile}
					handleEmailChange={handleEmailChange}
					handleForenameChange={handleForenameChange}
					handleSurnameChange={handleSurnameChange}
					handlePhoneChange={handlePhoneChange}
					handleMobileChange={handleMobileChange}
					editing={editing}
					updateUser={updateUser}
					onCheckboxClick={onCheckboxClick}
					userExistsForCustomer={userExistsForCustomer}
					facilities={customerFacilities}
					handleSeeMore={handleFetchSpecification}
					withFacilities
				/>
			</>
			{hasPermission(PERMISSIONS.CAN_EDIT_CUSTOMER_CONSENT) && confirmDeletion && (
				<Popup
					type="command"
					text={`Please confirm that you want to delete customer with ID ${customer?.customerId}.`}
					onConfirm={handleDisableCustomer}
					confirm={'Confirm'}
					onClose={handleCloseModal}
					close={'Cancel'}
					textStyle={{
						marginTop: 0,
						padding: 10,
					}}
					disableConfirm={!customer || loadingDisable}
				/>
			)}
		</div>
	);
};

export default CustomerInformationSection;
