/* Copyright Levelise Ltd 2019-2025 */
import config from '../config';
import TokenService from './token-service';

const FleetService = {
	getUserPreferences(username) {
		return fetch(`${config.REST_URI}/fleet/user/${username}/preferences`, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${TokenService.getDataFromAuthToken('access_token')}`,
			},
		}).then((res) => {
			if (res.ok) {
				return res.json();
			}
			return res.json().then((e) => {
				e.status = res.status;
				return Promise.reject(e);
			});
		});
	},

	addUser(obj) {
		return fetch(`${config.REST_URI}/fleet/user`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${TokenService.getDataFromAuthToken('access_token')}`,
			},
			body: JSON.stringify(obj),
		}).then((res) => {
			if (res.ok) {
				return res.json();
			}
			return res.json().then((e) => {
				e.status = res.status;
				return Promise.reject(e);
			});
		});
	},

	setUserPreferences(username, preferences) {
		return fetch(`${config.REST_URI}/fleet/user/${username}/preferences`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${TokenService.getDataFromAuthToken('access_token')}`,
			},
			body: JSON.stringify(preferences),
		}).then((res) => {
			if (res.ok) {
				return res.json();
			}
			return res.json().then((e) => {
				e.status = res.status;
				return Promise.reject(e);
			});
		});
	},

	getFaultCode() {
		return fetch(`${config.REST_URI}/fleet/faultcodes`, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${TokenService.getDataFromAuthToken('access_token')}`,
			},
		}).then((res) => {
			if (res.ok) {
				return res.json();
			}
			return res.json().then((e) => {
				e.status = res.status;
				return Promise.reject(e);
			});
		});
	},

	getRegionalDrus() {
		return fetch(`${config.REST_URI}/fleet/regions/drus`, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${TokenService.getDataFromAuthToken('access_token')}`,
			},
		}).then((res) => {
			if (res.ok) {
				return res.json();
			}
			return res.json().then((e) => {
				e.status = res.status;
				return Promise.reject(e);
			});
		});
	},

	getSettlementMethodDrus() {
		return fetch(`${config.REST_URI}/fleet/settlement/drus`, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${TokenService.getDataFromAuthToken('access_token')}`,
			},
		}).then((res) => {
			if (res.ok) {
				return res.json();
			}
			return res.json().then((e) => {
				e.status = res.status;
				return Promise.reject(e);
			});
		});
	},

	getBatterySystems() {
		return fetch(`${config.REST_URI}/fleet/batteries`, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${TokenService.getDataFromAuthToken('access_token')}`,
			},
		}).then((res) => {
			if (res.ok) {
				return res.json();
			}
			return res.json().then((e) => {
				e.status = res.status;
				return Promise.reject(e);
			});
		});
	},

	getBatterySystemDrus() {
		return fetch(`${config.REST_URI}/fleet/batteries/drus`, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${TokenService.getDataFromAuthToken('access_token')}`,
			},
		}).then((res) => {
			if (res.ok) {
				return res.json();
			}
			return res.json().then((e) => {
				e.status = res.status;
				return Promise.reject(e);
			});
		});
	},

	getHwTanks() {
		return fetch(`${config.REST_URI}/fleet/hw-tanks`, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${TokenService.getDataFromAuthToken('access_token')}`,
			},
		}).then((res) => {
			if (res.ok) {
				return res.json();
			}
			return res.json().then((e) => {
				e.status = res.status;
				return Promise.reject(e);
			});
		});
	},

	getDruList() {
		return fetch(`${config.REST_URI}/fleet/user/drus`, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${TokenService.getDataFromAuthToken('access_token')}`,
			},
		}).then((res) => {
			if (res.ok) {
				return res.json();
			}
			return res.json().then((e) => {
				e.status = res.status;
				return Promise.reject(e);
			});
		});
	},

	getDruStatuses() {
		return fetch(`${config.REST_URI}/fleet/user/drus/status?optimised&identified`, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${TokenService.getDataFromAuthToken('access_token')}`,
			},
		}).then((res) => {
			if (res.ok) {
				return res.json();
			}
			return res.json().then((e) => {
				e.status = res.status;
				return Promise.reject(e);
			});
		});
	},

	getDruStatusesAsCsv() {
		return fetch(`${config.REST_URI}/fleet/user/drus/status/csv`, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${TokenService.getDataFromAuthToken('access_token')}`,
			},
		});
	},

	getUserCustomersCSV(username, password) {
		const pass = new URLSearchParams({password})

		return fetch(`${config.REST_URI}/fleet/user/${username}/customers/csv?${pass.toString()}`, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${TokenService.getDataFromAuthToken('access_token')}`,
			},
		});
	},

	getCustomersFacilityStatusCSV(password) {
		const pass = new URLSearchParams({password})

		return fetch(`${config.REST_URI}/fleet/customers/facility/status/csv?${pass.toString()}`, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${TokenService.getDataFromAuthToken('access_token')}`,
			},
		});
	},

	getCustomersTariffStatusCSV(password) {
		const pass = new URLSearchParams({password})

		return fetch(`${config.REST_URI}/fleet/customers/tariff/status/csv?${pass.toString()}`, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${TokenService.getDataFromAuthToken('access_token')}`,
			},
		});
	},

	getPaymentOutcome(password) {
		const pass = new URLSearchParams({password})

		return fetch(`${config.REST_URI}/fleet/customers/credits/payment/outcome?${pass.toString()}`, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${TokenService.getDataFromAuthToken('access_token')}`,
			},
		});
	},
	getExcludedPayments(password) {
		const pass = new URLSearchParams({password})

		return fetch(`${config.REST_URI}/fleet/customers/credits/payment/excluded?${pass.toString()}`, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${TokenService.getDataFromAuthToken('access_token')}`,
			},
		});
	},

	generatePaymentFiles() {
		return fetch(`${config.REST_URI}/fleet/customers/credits/payment/request`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${TokenService.getDataFromAuthToken('access_token')}`,
			},
		}).then(res => {
			if (res.ok) {
				return res.json();
			}

			return res.json().then(e => {
				e.status = res.status;
				return Promise.reject(e);
			})
		});
	},

	getDruStatusesForAggFacility(aggregated_facility) {
		const params = `/fleet/aggregatedfacility/${aggregated_facility}/drus/status?optimised&identified`;
		return fetch(config.REST_URI + params, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${TokenService.getDataFromAuthToken('access_token')}`,
			},
		}).then((res) => {
			if (res.ok) {
				return res.json();
			}
			return res.json().then((e) => {
				e.status = res.status;
				return Promise.reject(e);
			});
		});
	},

	getFacilityByDruId(dru_id) {
		return fetch(`${config.REST_URI}/fleet/dru/${dru_id}/facility`, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${TokenService.getDataFromAuthToken('access_token')}`,
			},
		}).then((res) => {
			if (res.ok) {
				return res.json();
			}
			return res.json().then((e) => {
				e.status = res.status;
				return Promise.reject(e);
			});
		});
	},

	getCustomerByFacility(facility) {
		return fetch(`${config.REST_URI}/fleet/facility/${facility}/customer`, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${TokenService.getDataFromAuthToken('access_token')}`,
			},
		}).then((res) => {
			if (res.ok) {
				return res.json();
			}
			return res.json().then((e) => {
				e.status = res.status;
				return Promise.reject(e);
			});
		});
	},

	getCustomerCredits(customerId) {
		return fetch(`${config.REST_URI}/fleet/customer/${customerId}/credits`, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${TokenService.getDataFromAuthToken('access_token')}`,
			},
		}).then((res) => {
			if (res.ok) {
				return res.json();
			}
			return res.json().then((e) => {
				e.status = res.status;
				return Promise.reject(e);
			});
		});
	},

	getCustomerFacilities(customerId) {
		return fetch(`${config.REST_URI}/fleet/customer/${customerId}/facilities`, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${TokenService.getDataFromAuthToken('access_token')}`,
			},
		}).then((res) => {
			if (res.ok) {
				return res.json();
			}
			return res.json().then((e) => {
				e.status = res.status;
				return Promise.reject(e);
			});
		});
	},

	getCustomerConsents(customerId) {
		return fetch(`${config.REST_URI}/fleet/customer/${customerId}/consent`, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${TokenService.getDataFromAuthToken('access_token')}`,
			},
		}).then((res) => {
			if (res.ok) {
				return res.json();
			}
			return res.json().then((e) => {
				e.status = res.status;
				return Promise.reject(e);
			});
		});
	},
	
	updateConsent(customerId, body) {
		return fetch(`${config.REST_URI}/fleet/customer/${customerId}/consent`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${TokenService.getDataFromAuthToken('access_token')}`,
			},
			body: JSON.stringify(body)
		}).then((res) => {
			if (res.ok) {
				return res.json();
			}
			return res.json().then((e) => {
				e.status = res.status;
				return Promise.reject(e);
			});
		});
	},

	getCustomerBankingInformation(customerId) {
		return fetch(`${config.REST_URI}/fleet/customer/${customerId}/settlement`, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${TokenService.getDataFromAuthToken('access_token')}`,
			},
		}).then((res) => {
			if (res.ok) {
				return res.json();
			}
			return res.json().then((e) => {
				e.status = res.status;
				return Promise.reject(e);
			});
		});
	},

	disableCustomer(customerId) {
		return fetch(`${config.REST_URI}/fleet/customer/${customerId}/disable`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${TokenService.getDataFromAuthToken('access_token')}`,
			},
		}).then((res) => {
			if (res.ok) {
				return res.json();
			}
			return res.json().then((e) => {
				e.status = res.status;
				return Promise.reject(e);
			});
		});
	},

	getCustomerTariff(customerId, tariffType) {
		return fetch(`${config.REST_URI}/fleet/customer/${customerId}/tariff/${tariffType}`, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${TokenService.getDataFromAuthToken('access_token')}`,
			},
		}).then((res) => {
			if (res.ok) {
				return res.json();
			}
			return res.json().then((e) => {
				e.status = res.status;
				return Promise.reject(e);
			});
		});
	},

	acceptCustomerEvidence(customerId, consentId) {
		return fetch(`${config.REST_URI}/fleet/customer/${customerId}/consent/${consentId}/accept-evidence`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${TokenService.getDataFromAuthToken('access_token')}`,
			},
		}).then((res) => {
			if (res.ok) {
				return res.json();
			}
			return res.json().then((e) => {
				e.status = res.status;
				return Promise.reject(e);
			});
		});
	},
	getCustomerBill(customerId, consentId) {
		return fetch(`${config.REST_URI}/fleet/customer/${customerId}/consent/${consentId}/bill`, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${TokenService.getDataFromAuthToken('access_token')}`,
			},
		}).then((res) => {
			if (res.ok) {
				return res.json();
			}
			return res.json().then((e) => {
				e.status = res.status;
				return Promise.reject(e);
			});
		});
	},
	uploadCustomerBill(customerId, consentId, typeObject) {
		return fetch(`${config.REST_URI}/fleet/customer/${customerId}/consent/${consentId}/bill`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${TokenService.getDataFromAuthToken('access_token')}`,
			},
			body: JSON.stringify(typeObject),
		}).then((res) => {
			if (res.ok) {
				return res.json();
			}
			return res.json().then((e) => {
				e.status = res.status;
				return Promise.reject(e);
			});
		});
	},

	uploadBillToS3(url, method, body) {
		return fetch(url, {
			method: method || 'PUT',
			body,
		}).then((res) => {
			if (res.status === 200) {
				return Promise.resolve(res);
			}

			return Promise.reject(res);
		});
	},

	getUserCustomers(username, ids = null, search = null) {
		const obj = {};
		if (ids) {
			obj.ids = ids;
		}
		if (search) {
			obj.search = search;
		}

		const params = new URLSearchParams(obj);

		return fetch(`${config.REST_URI}/fleet/user/${username}/customers?${params.toString()}`, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${TokenService.getDataFromAuthToken('access_token')}`,
			},
		}).then((res) => {
			if (res.ok) {
				return res.json();
			}
			return res.json().then((e) => {
				e.status = res.status;
				return Promise.reject(e);
			});
		});
	},

	getCustomerUsers(customerId) {
		return fetch(`${config.REST_URI}/fleet/customer/${customerId}/users`, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${TokenService.getDataFromAuthToken('access_token')}`,
			},
		}).then((res) => {
			if (res.ok) {
				return res.json();
			}
			return res.json().then((e) => {
				e.status = res.status;
				return Promise.reject(e);
			});
		});
	},
	updateCustomer(customerId, changeObject) {
		return fetch(`${config.REST_URI}/fleet/customer/${customerId}`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${TokenService.getDataFromAuthToken('access_token')}`,
			},
			body: JSON.stringify(changeObject),
		}).then((res) => {
			if (res.ok) {
				return res.json();
			}
			return res.json().then((e) => {
				e.status = res.status;
				return Promise.reject(e);
			});
		});
	},
	modifyUserEmail(username, changeObject) {
		return fetch(`${config.REST_URI}/fleet/user/${username}/email`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${TokenService.getDataFromAuthToken('access_token')}`,
			},
			body: JSON.stringify(changeObject),
		}).then((res) => {
			if (res.ok) {
				return res.json();
			}
			return res.json().then((e) => {
				e.status = res.status;
				return Promise.reject(e);
			});
		});
	},

	getFleetRecords(resolution, since = null, before = null) {
		const param_obj = { since: since, before: before };
		const api_url = `${config.REST_URI}/fleet/records?resolution=${resolution}`;
		const params = Object.keys(param_obj).reduce((str, key) => {
			if (!!param_obj[key]) return str + '&' + key + '=' + param_obj[key];
			return str;
		}, '');
		return fetch(api_url + params, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${TokenService.getDataFromAuthToken('access_token')}`,
			},
		}).then((res) => {
			if (res.ok) {
				return res.json();
			}
			return res.json().then((e) => {
				e.status = res.status;
				return Promise.reject(e);
			});
		});
	},

	getAfs() {
		return fetch(`${config.REST_URI}/fleet/afs?contracts`, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${TokenService.getDataFromAuthToken('access_token')}`,
			},
		}).then((res) => {
			if (res.ok) {
				return res.json();
			}
			return res.json().then((e) => {
				e.status = res.status;
				return Promise.reject(e);
			});
		});
	},

	getAggFacilityRecords(aggregated_facility_name, resolution, since = null, before = null) {
		const param_obj = { since: since, before: before };
		const api_url =
			`${config.REST_URI}/fleet/aggregatedfacility/${aggregated_facility_name}/records?` +
			`resolution=${resolution}`;
		const params = Object.keys(param_obj).reduce((str, key) => {
			if (!!param_obj[key]) return str + '&' + key + '=' + param_obj[key];
			return str;
		}, '');
		return fetch(api_url + params, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${TokenService.getDataFromAuthToken('access_token')}`,
			},
		}).then((res) => {
			if (res.ok) {
				return res.json();
			}
			return res.json().then((e) => {
				e.status = res.status;
				return Promise.reject(e);
			});
		});
	},

	getAggFacilityPpm(aggregated_facility_name, resolution, since = null, before = null) {
		const param_obj = { since: since, before: before };
		const api_url =
			`${config.REST_URI}/fleet/aggregatedfacility/${aggregated_facility_name}/ppm?resolution=${resolution}`;
		const params = Object.keys(param_obj).reduce((str, key) => {
			if (!!param_obj[key]) return str + '&' + key + '=' + param_obj[key];
			return str;
		}, '');
		return fetch(api_url + params, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${TokenService.getDataFromAuthToken('access_token')}`,
			},
		}).then((res) => {
			if (res.ok) {
				return res.json();
			}
			return res.json().then((e) => {
				e.status = res.status;
				return Promise.reject(e);
			});
		});
	},

	getFleetConnectedDrus(resolution, since = null, before = null) {
		const param_obj = { since: since, before: before };
		const api_url = `${config.REST_URI}/fleet/batteries/connected?resolution=${resolution}`;
		const params = Object.keys(param_obj).reduce((str, key) => {
			if (!!param_obj[key]) return str + '&' + key + '=' + param_obj[key];
			return str;
		}, '');
		return fetch(api_url + params, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${TokenService.getDataFromAuthToken('access_token')}`,
			},
		}).then((res) => {
			if (res.ok) {
				return res.json();
			}
			return res.json().then((e) => {
				e.status = res.status;
				return Promise.reject(e);
			});
		});
	},

	getAggFacilityConnectedDrus(aggregated_facility_name, resolution, since = null, before = null) {
		const param_obj = { since: since, before: before };
		const api_url =
			config.REST_URI +
			`/fleet/aggregatedfacility/${aggregated_facility_name}/batteries/connected?` +
			`resolution=${resolution}`;
		const params = Object.keys(param_obj).reduce((str, key) => {
			if (!!param_obj[key]) return str + '&' + key + '=' + param_obj[key];
			return str;
		}, '');
		return fetch(api_url + params, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${TokenService.getDataFromAuthToken('access_token')}`,
			},
		}).then((res) => {
			if (res.ok) {
				return res.json();
			}
			return res.json().then((e) => {
				e.status = res.status;
				return Promise.reject(e);
			});
		});
	},

	getFleetSoc(resolution, since = null, before = null) {
		const param_obj = { since: since, before: before };
		const api_url = `${config.REST_URI}/fleet/batteries/soc?resolution=${resolution}`;
		const params = Object.keys(param_obj).reduce((str, key) => {
			if (!!param_obj[key]) return str + '&' + key + '=' + param_obj[key];
			return str;
		}, '');
		return fetch(api_url + params, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${TokenService.getDataFromAuthToken('access_token')}`,
			},
		}).then((res) => {
			if (res.ok) {
				return res.json();
			}
			return res.json().then((e) => {
				e.status = res.status;
				return Promise.reject(e);
			});
		});
	},

	getAggFacilitySoc(aggregated_facility_name, resolution, since = null, before = null) {
		const param_obj = { since: since, before: before };
		const api_url =
			config.REST_URI +
			`/fleet/aggregatedfacility/${aggregated_facility_name}/batteries/soc?` +
			`resolution=${resolution}`;
		const params = Object.keys(param_obj).reduce((str, key) => {
			if (!!param_obj[key]) return str + '&' + key + '=' + param_obj[key];
			return str;
		}, '');
		return fetch(api_url + params, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${TokenService.getDataFromAuthToken('access_token')}`,
			},
		}).then((res) => {
			if (res.ok) {
				return res.json();
			}
			return res.json().then((e) => {
				e.status = res.status;
				return Promise.reject(e);
			});
		});
	},

	getFleetTemp(resolution, since = null, before = null) {
		const param_obj = { since: since, before: before };
		const api_url = `${config.REST_URI}/fleet/batteries/temperature?resolution=${resolution}`;
		const params = Object.keys(param_obj).reduce((str, key) => {
			if (!!param_obj[key]) return str + '&' + key + '=' + param_obj[key];
			return str;
		}, '');
		return fetch(api_url + params, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${TokenService.getDataFromAuthToken('access_token')}`,
			},
		}).then((res) => {
			if (res.ok) {
				return res.json();
			}
			return res.json().then((e) => {
				e.status = res.status;
				return Promise.reject(e);
			});
		});
	},

	getAggFacilityTemp(aggregated_facility_name, resolution, since = null, before = null) {
		const param_obj = { since: since, before: before };
		const api_url =
			config.REST_URI +
			`/fleet/aggregatedfacility/${aggregated_facility_name}/batteries/temperature?` +
			`resolution=${resolution}`;
		const params = Object.keys(param_obj).reduce((str, key) => {
			if (!!param_obj[key]) return str + '&' + key + '=' + param_obj[key];
			return str;
		}, '');
		return fetch(api_url + params, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${TokenService.getDataFromAuthToken('access_token')}`,
			},
		}).then((res) => {
			if (res.ok) {
				return res.json();
			}
			return res.json().then((e) => {
				e.status = res.status;
				return Promise.reject(e);
			});
		});
	},

	getFleetFlow(resolution, since = null, before = null) {
		const param_obj = { since: since, before: before };
		const api_url = `${config.REST_URI}/fleet/hw-tanks/flow?resolution=${resolution}`;
		const params = Object.keys(param_obj).reduce((str, key) => {
			if (!!param_obj[key]) return str + '&' + key + '=' + param_obj[key];
			return str;
		}, '');
		return fetch(api_url + params, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${TokenService.getDataFromAuthToken('access_token')}`,
			},
		}).then((res) => {
			if (res.ok) {
				return res.json();
			}
			return res.json().then((e) => {
				e.status = res.status;
				return Promise.reject(e);
			});
		});
	},

	getAggFacilityFlow(aggregated_facility_name, resolution, since = null, before = null) {
		const param_obj = { since: since, before: before };
		const api_url =
			config.REST_URI +
			`/fleet/aggregatedfacility/${aggregated_facility_name}/hw-tanks/flow?` +
			`resolution=${resolution}`;
		const params = Object.keys(param_obj).reduce((str, key) => {
			if (!!param_obj[key]) return str + '&' + key + '=' + param_obj[key];
			return str;
		}, '');
		return fetch(api_url + params, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${TokenService.getDataFromAuthToken('access_token')}`,
			},
		}).then((res) => {
			if (res.ok) {
				return res.json();
			}
			return res.json().then((e) => {
				e.status = res.status;
				return Promise.reject(e);
			});
		});
	},

	getFleetHwTemperature(resolution, since = null, before = null) {
		const param_obj = { since: since, before: before };
		const api_url = `${config.REST_URI}/fleet/hw-tanks/temperature?resolution=${resolution}`;
		const params = Object.keys(param_obj).reduce((str, key) => {
			if (!!param_obj[key]) return str + '&' + key + '=' + param_obj[key];
			return str;
		}, '');
		return fetch(api_url + params, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${TokenService.getDataFromAuthToken('access_token')}`,
			},
		}).then((res) => {
			if (res.ok) {
				return res.json();
			}
			return res.json().then((e) => {
				e.status = res.status;
				return Promise.reject(e);
			});
		});
	},

	getAggFacilityHwTemperature(aggregated_facility_name, resolution, since = null, before = null) {
		const param_obj = { since: since, before: before };
		const api_url =
			config.REST_URI +
			`/fleet/aggregatedfacility/${aggregated_facility_name}/hw-tanks/temperature?` +
			`resolution=${resolution}`;
		const params = Object.keys(param_obj).reduce((str, key) => {
			if (!!param_obj[key]) return str + '&' + key + '=' + param_obj[key];
			return str;
		}, '');
		return fetch(api_url + params, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${TokenService.getDataFromAuthToken('access_token')}`,
			},
		}).then((res) => {
			if (res.ok) {
				return res.json();
			}
			return res.json().then((e) => {
				e.status = res.status;
				return Promise.reject(e);
			});
		});
	},

	getFacilitiesWithinAggFacilities(aggregated_facility) {
		return fetch(`${config.REST_URI}/fleet/aggregatedfacility/${aggregated_facility}/facilities`, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${TokenService.getDataFromAuthToken('access_token')}`,
			},
		}).then((res) => {
			if (res.ok) {
				return res.json();
			}
			return res.json().then((e) => {
				e.status = res.status;
				return Promise.reject(e);
			});
		});
	},

	assignFacilityToAggFacility(facility, body) {
		return fetch(`${config.REST_URI}/fleet/facility/${facility}/aggregatedfacility`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${TokenService.getDataFromAuthToken('access_token')}`,
			},
			body: JSON.stringify(body),
		}).then((res) => {
			if (res.ok) {
				return res.json();
			}
			return res.json().then((e) => {
				e.status = res.status;
				return Promise.reject(e);
			});
		});
	},

	getFacilities() {
		return fetch(`${config.REST_URI}/fleet/user/facilities`, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${TokenService.getDataFromAuthToken('access_token')}`,
			},
		}).then((res) => {
			if (res.ok) {
				return res.json();
			}
			return res.json().then((e) => {
				e.status = res.status;
				return Promise.reject(e);
			});
		});
	},

	getFacility(facility, since = 0) {
		return fetch(`${config.REST_URI}/fleet/facility/${facility}?since=${since}`, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${TokenService.getDataFromAuthToken('access_token')}`,
			},
		}).then((res) => {
			if (res.ok) {
				return res.json();
			}
			return res.json().then((e) => {
				e.status = res.status;
				return Promise.reject(e);
			});
		});
	},

	setFacility(facility, body) {
		return fetch(`${config.REST_URI}/fleet/facility/${facility}`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${TokenService.getDataFromAuthToken('access_token')}`,
			},
			body: JSON.stringify(body),
		}).then((res) => {
			if (res.ok) {
				return res.json();
			}
			return res.json().then((e) => {
				e.status = res.status;
				return Promise.reject(e);
			});
		});
	},

	createCustomer(body) {
		return fetch(`${config.REST_URI}/fleet/customer`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${TokenService.getDataFromAuthToken('access_token')}`,
			},
			body: JSON.stringify(body),
		}).then((res) => {
			if (res.ok) {
				return res.json();
			}
			return res.json().then((e) => {
				e.status = res.status;
				return Promise.reject(e);
			});
		});
	},

	setFacilityCommand(facility, command) {
		return fetch(`${config.REST_URI}/fleet/facility/${facility}/dru-command?command=${command}`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${TokenService.getDataFromAuthToken('access_token')}`,
			},
		}).then((res) => {
			if (res.ok) {
				return res.json();
			}
			return res.json().then((e) => {
				e.status = res.status;
				return Promise.reject(e);
			});
		});
	},

	getFacilityStatus(facility) {
		return fetch(`${config.REST_URI}/fleet/facility/${facility}/system-status`, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${TokenService.getDataFromAuthToken('access_token')}`,
			},
		}).then((res) => {
			if (res.ok) {
				return res.json();
			}
			return res.json().then((e) => {
				e.status = res.status;
				return Promise.reject(e);
			});
		});
	},

	getFacilityRecords(facility, resolution, since = null, before = null, count = null) {
		const param_obj = { since: since, before: before, count: count };
		const api_url = `${config.REST_URI}/fleet/facility/${facility}/records?resolution=${resolution}`;
		const params = Object.keys(param_obj).reduce((str, key) => {
			if (!!param_obj[key]) return str + '&' + key + '=' + param_obj[key];
			return str;
		}, '');
		return fetch(api_url + params, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${TokenService.getDataFromAuthToken('access_token')}`,
			},
		}).then((res) => {
			if (res.ok) {
				return res.json();
			}
			return res.json().then((e) => {
				e.status = res.status;
				return Promise.reject(e);
			});
		});
	},

	getFacilityConnectivityHistory(facility, resolution, since = null, before = null) {
		const param_obj = { since: since, before: before };
		const api_url = `${config.REST_URI}/fleet/facility/${facility}/connectivity?resolution=${resolution}`;
		const params = Object.keys(param_obj).reduce((str, key) => {
			if (!!param_obj[key]) return str + '&' + key + '=' + param_obj[key];
			return str;
		}, '');

		return fetch(api_url + params, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${TokenService.getDataFromAuthToken('access_token')}`,
			},
		}).then((res) => {
			if (res.ok) {
				return res.json();
			}
			return res.json().then((e) => {
				e.status = res.status;
				return Promise.reject(e);
			});
		});
	},

	getFacilityRecordsAsCsv(facility, resolution, since = null, before = null) {
		const param_obj = { since: since, before: before };
		const api_url = `/fleet/facility/${facility}/records/csv?resolution=${resolution}`;
		const params = Object.keys(param_obj).reduce((str, key) => {
			if (!!param_obj[key]) return str + '&' + key + '=' + param_obj[key];
			return str;
		}, '');
		return fetch(config.REST_URI + api_url + params, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${TokenService.getDataFromAuthToken('access_token')}`,
			},
		});
	},

	getLogMessagesForFacility(facility, param_obj, count = 50) {
		const params_str = Object.keys(param_obj).reduce((str, key) => {
			if (!!param_obj[key]) return str + '&' + key + '=' + param_obj[key];
			return str;
		}, `count=${count}`);
		return fetch(`${config.REST_URI}/fleet/facility/${facility}/messages?${params_str}`, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${TokenService.getDataFromAuthToken('access_token')}`,
			},
		}).then((res) => {
			if (res.ok) {
				return res.json();
			}
			return res.json().then((e) => {
				e.status = res.status;
				return Promise.reject(e);
			});
		});
	},

	getFacilityQualifications(facility) {
		return fetch(`${config.REST_URI}/fleet/facility/${facility}/qualifications`, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${TokenService.getDataFromAuthToken('access_token')}`,
			},
		}).then((res) => {
			if (res.ok) {
				return res.json();
			}
			return res.json().then((e) => {
				e.status = res.status;
				return Promise.reject(e);
			});
		});
	},

	getNewDru() {
		return fetch(`${config.REST_URI}/fleet/dru/new`, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${TokenService.getDataFromAuthToken('access_token')}`,
			},
		}).then((res) => {
			if (res.ok) {
				return res.json();
			}
			return res.json().then((e) => {
				e.status = res.status;
				return Promise.reject(e);
			});
		});
	},

	addNewFacility(facility) {
		return fetch(`${config.REST_URI}/fleet/facility`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${TokenService.getDataFromAuthToken('access_token')}`,
			},
			body: JSON.stringify(facility),
		}).then((res) => {
			if (res.ok) {
				return res.json();
			}
			return res.json().then((e) => {
				e.status = res.status;
				return Promise.reject(e);
			});
		});
	},

	getOperatingMode(facility) {
		return fetch(`${config.REST_URI}/fleet/facility/${facility}/operating-mode`, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${TokenService.getDataFromAuthToken('access_token')}`,
			},
		}).then((res) => {
			if (res.ok) {
				return res.json();
			}
			return res.json().then((e) => {
				e.status = res.status;
				return Promise.reject(e);
			});
		});
	},

	setOperatingMode(facility, mode) {
		return fetch(`${config.REST_URI}/fleet/facility/${facility}/operating-mode?mode=${mode}`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${TokenService.getDataFromAuthToken('access_token')}`,
			},
		}).then((res) => {
			if (res.ok) {
				return res.json();
			}
			return res.json().then((e) => {
				e.status = res.status;
				return Promise.reject(e);
			});
		});
	},

	createCredits(body) {
		return fetch(`${config.REST_URI}/fleet/customers/credits`, {
			method: 'POST',
			headers: {
				Authorization: `Bearer ${TokenService.getDataFromAuthToken('access_token')}`,
			},
			body,
		}).then((res) => {
			if (res.ok) {
				return res.json();
			}
			return res.json().then((e) => {
				e.status = res.status;
				return Promise.reject(e);
			});
		});
	},

	uploadProcessedCredits(body) {
		return fetch(`${config.REST_URI}/fleet/customers/credits/payment/complete`, {
			method: 'POST',
			headers: {
				Authorization: `Bearer ${TokenService.getDataFromAuthToken('access_token')}`,
			},
			body,
		}).then((res) => {
			if (res.ok) {
				return res.json();
			}
			return res.json().then((e) => {
				e.status = res.status;
				return Promise.reject(e);
			});
		});
	},

	getUserDetails(username) {
		return fetch(`${config.REST_URI}/fleet/user/${username}/details`, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${TokenService.getDataFromAuthToken('access_token')}`,
			}
		}).then((res) => {
			if (res.ok) {
				return res.json();
			}

			return res.json().then((e) => {
				e.status = res.status;
				return Promise.reject(e);
			});
		});
	},
	async completeCustomerCredits(customerId, body) {
		const res = await fetch(`${config.REST_URI}/fleet/customer/${customerId}/credits/complete`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${TokenService.getDataFromAuthToken('access_token')}`,
			},
			body: JSON.stringify(body)
		});

		if (res.ok) {
			return res.json();
		}

		const e = await res.json();
		e.status = res.status;
		return await Promise.reject(e);
	},
	async createCustomerCredits(customerId, body) {
		const res = await fetch(`${config.REST_URI}/fleet/customer/${customerId}/credits`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${TokenService.getDataFromAuthToken('access_token')}`,
			},
			body: JSON.stringify(body)
		});

		if (res.ok) {
			return res.json();
		}

		const e = await res.json();
		e.status = res.status;
		return await Promise.reject(e);
	},
	async getCustomerFiles(customerId) {
		const res = await fetch(`${config.REST_URI}/fleet/customer/${customerId}/files`, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${TokenService.getDataFromAuthToken('access_token')}`,
			},
		});
		if (res.ok) {
			return res.json();
		}
		const e = await res.json();
		e.status = res.status;
		return await Promise.reject(e);
	},
	async getCustomerFile(customerId, fileId) {
		const res = await fetch(`${config.REST_URI}/fleet/customer/${customerId}/file/${fileId}`, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${TokenService.getDataFromAuthToken('access_token')}`,
			},
		});
		if (res.ok) {
			return res.json();
		}
		const e = await res.json();
		e.status = res.status;
		return await Promise.reject(e);
	},
	async getInstallationCompanies() {
		const res = await fetch(`${config.REST_URI}/fleet/shop/companies`, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${TokenService.getDataFromAuthToken('access_token')}`,
			},
		});
		if (res.ok) {
			return res.json();
		}
		const e = await res.json();
		e.status = res.status;
		return await Promise.reject(e);
	},
	async getOrdersForInstallationCompany(companyId) {
		const res = await fetch(`${config.REST_URI}/fleet/shop/company/${companyId}/orders`, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${TokenService.getDataFromAuthToken('access_token')}`,
			},
		});
		if (res.ok) {
			return res.json();
		}
		const e = await res.json();
		e.status = res.status;
		return await Promise.reject(e);
	},
	async getCreditRuns() {
		const res = await fetch(`${config.REST_URI}/fleet/customers/credits/payment/runs`, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${TokenService.getDataFromAuthToken('access_token')}`
			}
		});

		if (res.ok) {
			return res.json();
		}

		const e = await res.json();
		e.status = res.status;
		return await Promise.reject(e);
	},
	async getCreditRunFile(fileId) {
		const res = await fetch(`${config.REST_URI}/fleet/customers/credits/payment/file/${fileId}`, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${TokenService.getDataFromAuthToken('access_token')}`,
			},
		});
		if (res.ok) {
			return res.json();
		}
		const e = await res.json();
		e.status = res.status;
		return await Promise.reject(e);
	},
	async getFacilityForecasts(facility, resolution, since = null, before = null, count = null) {
		const param_obj = { since: since, before: before, count: count };
		const api_url = `${config.REST_URI}/fleet/facility/${facility}/forecasts?resolution=${resolution}`;

		const params = Object.keys(param_obj).reduce((str, key) => {
			if (!!param_obj[key]) return str + '&' + key + '=' + param_obj[key];
			return str;
		}, '');

		const res = await fetch(api_url + params, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${TokenService.getDataFromAuthToken('access_token')}`,
			},
		});
		if (res.ok) {
			return res.json();
		}
		const e = await res.json();
		e.status = res.status;
		return await Promise.reject(e);
	},
	async getCustomersTariffStatus() {
		const api_url = `${config.REST_URI}/fleet/customers/tariff/status`;

		const res = await fetch(api_url, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${TokenService.getDataFromAuthToken('access_token')}`,
			},
		});
		if (res.ok) {
			return res.json();
		}
		const e = await res.json();
		e.status = res.status;
		return await Promise.reject(e);
	},
};

export default FleetService;
