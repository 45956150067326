/* Copyright Levelise Ltd 2024 - 2025 */
import React from 'react';
import { getFacilityCode } from '../../utils/utils';

const CustomerFacilitySpecification = ({ facility, handleSeeMore }) => {
	if (Object.keys(facility).length) {
		return (
			<ul>
				<li>
					<span className="strong specification">ID (Code) </span>
					<span className="em specification">
						{facility?.id ? `${facility.id} (${getFacilityCode(facility.id) || '—'})` : '—'}
						{' '}
						{facility?.name ? (
							<span
								style={{
									color: 'blue',
									textDecoration: 'underline',
									cursor: 'pointer',
									fontSize: 10,
									whiteSpace: 'nowrap',
								}}
								onClick={() => handleSeeMore(facility.name)}
							>
								See more
							</span>
						) : null}
					</span>
				</li>

				<li>
					<span className="strong customer">DRU ID</span>
					<span className="em customer">{facility?.druId ? facility.druId : '—'}</span>
				</li>

				<li>
					<span className="strong specification">Balancing Services Unit </span>
					<span className="em specification">
						{facility?.aggregatedFacility ? facility.aggregatedFacility.name : '—'}
					</span>
				</li>
				<li>
					<span className="strong specification">Dispatch Contract </span>
					<span className="em specification">
						{facility?.aggregatedFacility
							? `${facility.aggregatedFacility.dispatch} ${facility.aggregatedFacility.unit}`
							: '—'}
					</span>
				</li>
			</ul>
		);
	}
};

export default CustomerFacilitySpecification;
