/* Copyright Levelise Ltd 2025 */
import { createContext, useState } from 'react';

const defaultValue = {
	selectedDirectionTab: 'import',
	selectedCaptureMethod: '',
	selectedProvider: '',

	setSelectedDirectionTab: () => {},
	setSelectedCaptureMethod: () => {},
	setSelectedProvider: () => {},
};

const FleetCustomersContext = createContext(defaultValue);

export default FleetCustomersContext;

export function FleetCustomersProvider({ children }) {
	const [selectedDirectionTab, setSelectedDirectionTab] = useState('import');
	const [selectedCaptureMethod, setSelectedCaptureMethod] = useState('');
	const [selectedProvider, setSelectedProvider] = useState('');

	const contextValues = {
		selectedDirectionTab,
		selectedCaptureMethod,
		selectedProvider,

		setSelectedDirectionTab,
		setSelectedCaptureMethod,
		setSelectedProvider,
	};

	return <FleetCustomersContext.Provider value={contextValues}>{children}</FleetCustomersContext.Provider>;
}
