/* Copyright Levelise Ltd 2019-2025 */
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { AdminProvider } from './contexts/AdminContext';
import { UserProvider } from './contexts/UserContext';
import { FleetProvider } from './contexts/FleetContext';
import { FacilityProvider } from './contexts/FacilityContext';
import App from './components/App/App';
import './assets/fonts/Inter-Bold.ttf';
import './assets/fonts/Inter-Medium.ttf';
import './assets/fonts/Inter-Light.ttf';
import './index.css';

import { positions, Provider as AlertProvider } from "react-alert";
import AlertTemplate from 'react-alert-template-basic';
import { options } from './utils/utils';
import { FleetStatusProvider } from './contexts/FleetStatusContext';
import { FleetCustomersProvider } from './contexts/FleetCustomersContext';


const root = createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter >
        <AdminProvider>
            <UserProvider>
                <FleetProvider>
                <FleetStatusProvider>
                    <FacilityProvider>
                        <FleetCustomersProvider>
                        <AlertProvider template={AlertTemplate} {...options(positions)}>
                            <App />
                        </AlertProvider>
                        </FleetCustomersProvider>
                    </FacilityProvider>
                    </FleetStatusProvider>
                </FleetProvider>
            </UserProvider>
        </AdminProvider>
    </BrowserRouter>
);
