/* Copyright Levelise Ltd 2019-2024 */
import React, { useState, useEffect, useCallback } from 'react';
import { SpecInput, SpecLabel } from '../Form/index';
import Select from 'react-select';
import { getOptions } from '../../utils/utils';
import { GRID_CONNECTION_TYPE, GSP_GROUP, GSP_GROUP_ID_NAME } from '../../utils/specification';
import cx from 'classnames';
import { customStylesForEdit } from '../SpecificationForm/customStyles';
import InputWithErrorMessageAndUnit from '../reusableInputComponents/InputWithErrorMessageAndUnit';
import { EXPORT_LIMIT_ERROR, IMPORT_LIMIT_ERROR } from '../../utils/constants';

const styles = {
	gridListItem: { flexWrap: 'wrap' },
	valueAndInputContainer: {
		flex: 1,
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-end',
	},
	labelAndCheckboxContainer: { display: 'inline-flex', alignItems: 'center' },
};

const uk_g83_g98 = 'G83 / G98',
	uk_g59_g99 = 'G59 / G99',
	as_4777 = 'AS4777',
	_tz = 'Europe/London';

const CHANGE_DNO = 'change_dno';
const CHANGE_GSP = 'change_gsp';
const CHANGE_CONNECTION_TYPE = 'change_connection_type';
const CHANGE_IMPORT_LIMIT_W = 'change_import_limit_w';
const CHANGE_EXPORT_LIMIT_W = 'change_export_limit_w';
const CHANGE_SUPPLY_PHASES = 'change_supply_phases';

const GridSpecifications = ({
	facilityContext,
	editing,
	state,
	dispatch,
	importLimitError,
	setImportLimitError,
	exportLimitError,
	setExportLimitError,
}) => {
	const [timezone, setTimezone] = useState('');
	const [gspOptions, setGspOptions] = useState('');

	const getGspGroups = useCallback(() => {
		let gspGroupOptions = GSP_GROUP;

		if (!!state.dno && !!state.dno?.value) {
			const value = parseInt(state.dno?.value);
			if (10 <= value && value <= 23) {
				gspGroupOptions = { [GSP_GROUP_ID_NAME[value]]: GSP_GROUP[GSP_GROUP_ID_NAME[value]] };
			}
		}

		if (!!state.gspGroup && !gspGroupOptions.hasOwnProperty(state.gspGroup.value)) {
			dispatch({ type: CHANGE_GSP, payload: '' });
		}

		if (gspGroupOptions && Object.keys(gspGroupOptions).length === 1) {
			const keys = Object.keys(gspGroupOptions);
			dispatch({ type: CHANGE_GSP, payload: { value: keys[0], label: gspGroupOptions[keys[0]] } });
		}

		return gspGroupOptions;
	}, [state.dno, dispatch]);

	const getSupplyPhase = () => {
		return { 0: '0', 1: '1', 2: '2', 3: '3' };
	};

	const disableCheckbox = (zone) => {
		if (!!timezone) {
			return timezone.toLowerCase().includes(zone) ? false : true;
		}
		return false;
	};

	const onExportLimitChange = (e) => {
		if (!isNaN(e.target.value)) {
			if (e.target.value < 100) {
				setExportLimitError(EXPORT_LIMIT_ERROR);
			} else {
				setExportLimitError('');
			}
		}

		if (!e.target.value && !facilityContext?.facility?.exportLimitW) {
			setExportLimitError('');
		}

		dispatch({ type: CHANGE_EXPORT_LIMIT_W, payload: e.target.value });
	};

	const onImportLimitChange = (e) => {
		if (!isNaN(e.target.value)) {
			if (e.target.value < 100) {
				setImportLimitError(IMPORT_LIMIT_ERROR);
			} else {
				setImportLimitError('');
			}
		}

		if (!e.target.value && !facilityContext?.facility?.importLimitW) {
			setImportLimitError('');
		}

		dispatch({ type: CHANGE_IMPORT_LIMIT_W, payload: e.target.value });
	};

	useEffect(() => {
		if (Object.keys(facilityContext?.facility).length) {
			setTimezone(facilityContext.facility?.timezone);

			let currentDNOId = facilityContext.facility?.gridConnection.id || '';
			const label = GRID_CONNECTION_TYPE[currentDNOId];

			if (label) {
				const startIndex = label?.indexOf('(');
				const endIndex = label?.indexOf(')');
				if (endIndex > startIndex) {
					currentDNOId = label.slice(startIndex + 1, endIndex);
				}
			}

			dispatch({ type: CHANGE_DNO, payload: { value: currentDNOId + '', label } });

			const currentGSPValue = facilityContext.facility.gspGroup;
			const gspLabel = GSP_GROUP[facilityContext.facility.gspGroup];

			dispatch({ type: CHANGE_GSP, payload: { value: currentGSPValue, label: gspLabel } });

			dispatch({
				type: CHANGE_SUPPLY_PHASES,
				payload: {
					value: facilityContext.facility.supplyPhases,
					label: facilityContext.facility.supplyPhases,
				},
			});

			dispatch({
				type: CHANGE_CONNECTION_TYPE,
				payload: facilityContext.facility?.gridConnection?.connectionType,
			});

			dispatch({ type: CHANGE_IMPORT_LIMIT_W, payload: facilityContext.facility?.importLimitW });

			dispatch({ type: CHANGE_EXPORT_LIMIT_W, payload: facilityContext.facility?.exportLimitW });

			setImportLimitError('');
			setExportLimitError('');
		}
	}, [facilityContext, editing]);

	useEffect(() => {
		const gspOptionsRes = getOptions(getGspGroups());

		setGspOptions(gspOptionsRes);
	}, [getGspGroups]);

	if (!Object.keys(facilityContext?.facility).length) {
		return null;
	}

	return (
		<>
			<li>
				<span className="strong specification">Timezone </span>
				<span className="em specification">{facilityContext.facility.timezone}</span>
			</li>
			<li>
				<span className="strong specification">TNO </span>
				<span className="em specification">{facilityContext.facility.gridConnection.connectionTo}</span>
			</li>
			<li style={styles.gridListItem}>
				<span className="strong specification">DNO </span>
				<span className="em specification" style={styles.valueAndInputContainer}>
					{GRID_CONNECTION_TYPE[facilityContext.facility.gridConnection.id] || '—'}
				</span>
			</li>
			<li style={styles.gridListItem}>
				<span className="strong specification">GSP Group </span>
				<span className="em specification" style={styles.valueAndInputContainer}>
					{!editing
						? facilityContext.facility.hasOwnProperty('gspGroup') && !!facilityContext.facility.gspGroup
							? GSP_GROUP[facilityContext.facility.gspGroup]
							: '—'
						: null}
					{editing ? (
						<Select
							id="gspGroup"
							name="gspGroup"
							className="form-right"
							styles={customStylesForEdit()}
							value={state.gspGroup}
							onChange={(e) => dispatch({ type: CHANGE_GSP, payload: e })}
							placeholder="-- Select GSP Group --"
							options={gspOptions}
							isDisabled={timezone !== _tz || parseInt(state.dno?.value) < 24}
						/>
					) : null}
				</span>
			</li>
			<li>
				<span className="strong specification">Connection Type </span>
				<span className="em specification">
					{!editing ? facilityContext.facility.gridConnection.connectionType || '—' : null}
					{editing ? (
						<div
							className="connection-types"
							onChange={(e) => dispatch({ type: CHANGE_CONNECTION_TYPE, payload: e.target.value })}
						>
							<div style={styles.labelAndCheckboxContainer}>
								<SpecInput
									type="checkbox"
									id="G83/G98"
									name="connectionType"
									className="connection-type"
									onChange={() => {}}
									checked={state.connectionType === uk_g83_g98}
									disabled={disableCheckbox('london')}
									value="G83 / G98"
								/>
								<SpecLabel
									className={cx('connection-type-label', disableCheckbox('london') ? 'disabled' : '')}
									htmlFor="G83/G98"
								>
									G83/G98
								</SpecLabel>
							</div>
							<div style={styles.labelAndCheckboxContainer}>
								<SpecInput
									type="checkbox"
									id="G59/G99"
									name="connectionType"
									className="connection-type"
									onChange={() => {}}
									checked={state.connectionType === uk_g59_g99}
									disabled={disableCheckbox('london')}
									value="G59 / G99"
								/>
								<SpecLabel
									className={cx('connection-type-label', disableCheckbox('london') ? 'disabled' : '')}
									htmlFor="G59/G99"
								>
									G59/G99
								</SpecLabel>
							</div>
							<div style={styles.labelAndCheckboxContainer}>
								<SpecInput
									type="checkbox"
									id="AS4777"
									name="connectionType"
									className="connection-type"
									onChange={() => {}}
									checked={state.connectionType === as_4777}
									disabled={disableCheckbox('australia')}
									value="AS4777"
								/>
								<SpecLabel
									className={cx(
										'connection-type-label',
										disableCheckbox('australia') ? 'disabled' : ''
									)}
									htmlFor="AS4777"
								>
									AS4777
								</SpecLabel>
							</div>
						</div>
					) : null}
				</span>
			</li>
			<li>
				<span className="strong specification">Line Loss Factor </span>
				<span className="em specification">
					{facilityContext.facility.lineLossFactor ? facilityContext.facility.lineLossFactor : '—'}
				</span>
			</li>
			<li>
				<span className="strong specification">Import Limit </span>
				<span className="em specification">
					{!editing
						? facilityContext?.facility && Object.hasOwn(facilityContext.facility, 'importLimitW')
							? facilityContext.facility.importLimitW
							: '—'
						: null}
					{editing ? (
						<InputWithErrorMessageAndUnit
							id="importLimit"
							type="number"
							name="importLimit"
							className="form-right"
							value={state.importLimit}
							onChange={onImportLimitChange}
							placeholder="Import limit in W"
							style={{ height: 23, maxWidth: 150 }}
							errorMessage={importLimitError}
							unit="W"
						/>
					) : null}
					{!editing && facilityContext?.facility && Object.hasOwn(facilityContext.facility, 'importLimitW')
						? ' W'
						: ''}
				</span>
			</li>
			<li>
				<span className="strong specification">Export Limit </span>
				<span className="em specification">
					{!editing
						? facilityContext?.facility && Object.hasOwn(facilityContext.facility, 'exportLimitW')
							? facilityContext.facility.exportLimitW
							: '—'
						: null}
					{editing ? (
						<InputWithErrorMessageAndUnit
							id="exportLimit"
							type="number"
							name="exportLimit"
							className="form-right"
							value={state.exportLimit}
							onChange={onExportLimitChange}
							placeholder="Export limit in W"
							style={{ height: 23, maxWidth: 150 }}
							errorMessage={exportLimitError}
							unit="W"
						/>
					) : null}
					{!editing && facilityContext?.facility && Object.hasOwn(facilityContext.facility, 'exportLimitW')
						? ' W'
						: ''}
				</span>
			</li>
			<li style={styles.gridListItem}>
				<span className="strong specification">Supply Phases </span>
				<span className="em specification" style={styles.valueAndInputContainer}>
					{!editing
						? facilityContext?.facility && Object.hasOwn(facilityContext.facility, 'supplyPhases')
							? facilityContext.facility.supplyPhases
							: '—'
						: null}
					{editing ? (
						<Select
							id="supply-phases"
							name="supply-phases"
							className="form-right"
							styles={customStylesForEdit()}
							value={state.supplyPhases}
							onChange={(e) => dispatch({ type: CHANGE_SUPPLY_PHASES, payload: e })}
							placeholder="-- Select supply phases --"
							options={getOptions(getSupplyPhase())}
						/>
					) : null}
				</span>
			</li>
		</>
	);
};

export default GridSpecifications;
